<script setup lang="ts">
const { t } = useT();

const props = withDefaults(
	defineProps<{
		goal?: number;
		complete?: number;
	}>(),
	{
		goal: 100,
		complete: 0
	}
);

const pregressValue = computed(() => {
	if (props?.goal === props?.complete) {
		return "100%";
	}
	if (props?.complete < props?.goal) {
		return `${(100 / props?.goal) * props?.complete}%`;
	}

	return "8px";
});
</script>

<template>
	<div class="progress">
		<div class="header">
			<AText>{{ t("You saved") }}</AText>
			<MPrizeFund class="prize" variant="entries" icon="28/secret-coin" :icon-size="20">
				<AText type="small" as="div" class="progress-value color-gray-50" :modifiers="['bold']">
					{{ `${numberFormat(complete)}/${numberFormat(goal)}` }}
				</AText>
			</MPrizeFund>
		</div>
		<div class="progress-wrapper">
			<div class="progress-thumb" :style="{ width: pregressValue }" />
		</div>
	</div>
</template>

<style lang="scss" scoped>
.progress {
	position: relative;
	display: flex;
	gap: 4px;
	flex-direction: column;
}

.header {
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: space-between;
}

.progress-wrapper {
	width: 100%;
	height: 12px;
	border-radius: 10px;
	background: var(--gray-700);
	padding: 2px;
}

.progress-value {
	text-align: center;
	width: max-content;
}

.progress-thumb {
	transition: 0.3s;
	height: 100%;
	min-width: 8px;
	background: var(--alternative-green);
	border-radius: 10px;
}
</style>
